@import '../../variables.scss';

.login-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: $background-color;

    .auth-modal{
        @include auth-modal;
        width: 400px;
        .registration-form{
            width: 100%;
        }
        
        .submit-btn{
            @include submit-btn;
            margin: 25px 0px 0px 0px;
            width: 100%;
        }
        
    }

    p{
        font-family: $text-stack;
        font-size: 16px;
        margin-top: 20px;

        .hyperlink-text{
            text-decoration: none;
            color: $primary-color;
            font-weight: bold;
            padding-left: 5px;
        }
    }
}