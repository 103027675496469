@import '../../variables.scss';

.auth-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 91vh;
    background-color: #f4f4f4;

    .auth-modal{
        @include auth-modal;
    }
    .change-auth-text{
        font-family: $text-stack;
        color: #313131;
        font-size: 16px;
        margin-top: 20px;
        .hyperlink-text{
            text-decoration: none;
            color: $primary-color;
            font-weight: bold;
            padding-left: 5px;
        }
    }
}