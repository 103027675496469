@import '../../variables.scss';

.navbar-component{
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $dark-background;
    width: 100vw;
    height: 80px;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.2);
    z-index: 100;

    .left-container{
        margin-left: 50px;
        h1{
            color: white;
            margin: 0;
        }
    }

    .right-container{
        margin-right: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        padding: 0;
        height: 100%;

        .nav-link{
            
            color: white;
            text-decoration: none;
            font-size: 14px;
            padding-right: 20px;
        }
        .nav-btn{
            color: white;
            border: 1px solid white;
            border-radius: 50px;
            padding: 10px 25px;
            text-decoration: none;
            
            &:hover{
                background-color: white;
                color: $dark-background;
                font-weight: bold;
            }
        }

        .username{
            font-size: 16px;
            color: white;
            margin: 0;
            padding: 0px 0px;
            cursor: pointer;
            background-color: rgba(0,0,0,0);
            height: 100%;
            border-bottom: none;

            .ant-menu-submenu-title{
                line-height: 78px;
            }

            .ant-menu-submenu-active{
                border-bottom: none;
            }
        }
    }

    .ant-menu-submenu-open{
        border-bottom: none;
    }
}