@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}
.auth-page{display:flex;flex-direction:column;align-items:center;justify-content:center;height:91vh;background-color:#f4f4f4}.auth-page .auth-modal{background-color:#fff;padding:20px;box-shadow:0px 2px 6px rgba(0,0,0,0.1);border-radius:8px;border:1px solid rgba(0,0,0,0.15);display:flex;flex-direction:column;align-items:center;margin-top:-50px}.auth-page .auth-modal h2{font-family:"Lato",sans-serif;margin:0 0 30px 0;padding:0;font-weight:bold;font-size:35px}.auth-page .auth-modal .registration-form{font-family:"Lato",sans-serif}.auth-page .auth-modal .registration-form .input label{padding:15px 0 0 0;margin:0;text-transform:uppercase;white-space:nowrap;font-size:12px;color:#6d6d6d;height:20px}.auth-page .auth-modal .registration-form .input .input-box{width:300px}.auth-page .auth-modal .registration-form .check-container{width:50%;background-color:red}.auth-page .auth-modal .registration-form .button-container{display:flex;justify-content:center;align-items:center;padding:0}.auth-page .auth-modal .registration-form .button-container .submit-btn{background-color:#ED677B;border:1px solid #ED677B;padding:15px 40px;height:auto;text-transform:uppercase;font-size:12px;letter-spacing:2px}.auth-page .change-auth-text{font-family:"Lato",sans-serif;color:#313131;font-size:16px;margin-top:20px}.auth-page .change-auth-text .hyperlink-text{text-decoration:none;color:#ED677B;font-weight:bold;padding-left:5px}

.login-page{display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%;background-color:#f4f4f4}.login-page .auth-modal{background-color:#fff;padding:20px;box-shadow:0px 2px 6px rgba(0,0,0,0.1);border-radius:8px;border:1px solid rgba(0,0,0,0.15);display:flex;flex-direction:column;align-items:center;margin-top:-50px;width:400px}.login-page .auth-modal h2{font-family:"Lato",sans-serif;margin:0 0 30px 0;padding:0;font-weight:bold;font-size:35px}.login-page .auth-modal .registration-form{font-family:"Lato",sans-serif}.login-page .auth-modal .registration-form .input label{padding:15px 0 0 0;margin:0;text-transform:uppercase;white-space:nowrap;font-size:12px;color:#6d6d6d;height:20px}.login-page .auth-modal .registration-form .input .input-box{width:300px}.login-page .auth-modal .registration-form .check-container{width:50%;background-color:red}.login-page .auth-modal .registration-form .button-container{display:flex;justify-content:center;align-items:center;padding:0}.login-page .auth-modal .registration-form .button-container .submit-btn{background-color:#ED677B;border:1px solid #ED677B;padding:15px 40px;height:auto;text-transform:uppercase;font-size:12px;letter-spacing:2px}.login-page .auth-modal .registration-form{width:100%}.login-page .auth-modal .submit-btn{background-color:#ED677B;border:1px solid #ED677B;padding:15px 40px;height:auto;text-transform:uppercase;font-size:12px;letter-spacing:2px;margin:25px 0px 0px 0px;width:100%}.login-page p{font-family:"Lato",sans-serif;font-size:16px;margin-top:20px}.login-page p .hyperlink-text{text-decoration:none;color:#ED677B;font-weight:bold;padding-left:5px}

.navbar-component{margin:0;display:flex;align-items:center;justify-content:space-between;background-color:#313131;width:100vw;height:80px;box-shadow:0px 4px 4px rgba(0,0,0,0.2);z-index:100}.navbar-component .left-container{margin-left:50px}.navbar-component .left-container h1{color:white;margin:0}.navbar-component .right-container{margin-right:50px;display:flex;align-items:center;justify-content:space-between;color:white;padding:0;height:100%}.navbar-component .right-container .nav-link{color:white;text-decoration:none;font-size:14px;padding-right:20px}.navbar-component .right-container .nav-btn{color:white;border:1px solid white;border-radius:50px;padding:10px 25px;text-decoration:none}.navbar-component .right-container .nav-btn:hover{background-color:white;color:#313131;font-weight:bold}.navbar-component .right-container .username{font-size:16px;color:white;margin:0;padding:0px 0px;cursor:pointer;background-color:rgba(0,0,0,0);height:100%;border-bottom:none}.navbar-component .right-container .username .ant-menu-submenu-title{line-height:78px}.navbar-component .right-container .username .ant-menu-submenu-active{border-bottom:none}.navbar-component .ant-menu-submenu-open{border-bottom:none}

.dashboard-page{display:flex;flex-direction:column;height:100%;padding:2vw;margin-left:16vw;margin-right:16vw}.dashboard-page .item-container{display:flex;justify-content:space-between}.dashboard-page .item-container .email-icon-container{width:30vw}.dashboard-page .loader-skeleton{margin:10px 0px;height:50px;width:100%;border-radius:5px}.dashboard-page .normal-buttons{background:#389fbb;border:#389fbb}.dashboard-page .normal-buttons:hover{background:#1ebae6;border:#1ebae6}.dashboard-page .danger-buttons{background:#ED677B;border:#ED677B}

