@import '../../../variables.scss';

.dashboard-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2vw;
    margin-left: 16vw;
    margin-right: 16vw;

    .item-container {
        display: flex;
        justify-content: space-between;

        .email-icon-container {
            width: 30vw;
        }
    }

    .loader-skeleton {
        margin: 10px 0px;
        height: 50px;
        width: 100%;
        border-radius: 5px;
    }

    .normal-buttons {
        background: #389fbb;
        border: #389fbb;
    }

    .normal-buttons:hover {
        background: #1ebae6 ;            
        border: #1ebae6;
    }

    .danger-buttons {
        background:#ED677B;
        border: #ED677B;
    }
}