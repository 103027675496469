//Contains the main styleguide for the entire application
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

$heading-stack: 'Lato', sans-serif;
$text-stack: 'Lato', sans-serif;

$primary-color: #ED677B;
$secondary-color: #50D9FF;

$background-color: #f4f4f4;
$dark-background: #313131;
$container-primary: white;
$container-secondary: #f3f3f3;

$icon-color: #c4c4c4;

@mixin submit-btn{
    background-color: $primary-color;
    border: 1px solid $primary-color;
    padding: 15px 40px;
    height: auto;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
}

@mixin auth-modal{
    background-color: $container-primary;
    padding: 20px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;

    h2{
        font-family: $heading-stack;
        margin: 0 0 30px 0;
        padding: 0;
        font-weight: bold;
        font-size: 35px;
    }

    .registration-form{
        font-family: $text-stack;

        .input{
            
            label{
                padding: 15px 0 0 0;
                margin: 0;
                text-transform: uppercase;
                white-space: nowrap;
                font-size: 12px;
                color: #6d6d6d;
                height: 20px;
            }

            .input-box{
                width: 300px;
            }
        }
        .check-container{
            width: 50%;
            background-color: red;
        }
        .button-container{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;

            .submit-btn{
                @include submit-btn
            }
        }
    }
}
